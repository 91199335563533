import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint } from "../utils/endpoint";
const AllSaloons = (query) => {
  if (query) {
    return HTTP_CLIENT.get(Endpoint.AllSaloons + query);
  } else {
    return HTTP_CLIENT.get(Endpoint.AllSaloons);
  }
};
const SalonDelete = (id)=>{
  if(id){
      return HTTP_CLIENT.delete(Endpoint.SalonDelete+id)
  }
  
  }

const SalonDeactive = (id) => {
  if (id) {
    return HTTP_CLIENT.patch(Endpoint.salonDeactive + id);
  }
};

export { AllSaloons, SalonDeactive,SalonDelete };
