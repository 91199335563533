import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { ArrMap } from "../../utils/constant";
import "./style.css";

function LocationModal({ show, handleClose }) {
  return (
    <Dialog onClose={handleClose} className="d-flex flex-column" open={show}>
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="mb-0 p-2">All Available Locations</h6>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="ann-cancel"
        >
          <CancelIcon />
        </IconButton>
      </div>

      <ul className="list-group rounded-0  location-modal-body">
        {ArrMap.map((item, ind) => {
          return <li className="list-group-item">{item}</li>;
        })}
      </ul>
    </Dialog>
  );
}

export default LocationModal;
